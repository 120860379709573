<template>
	<div>
		<div v-if="profile.avatar == null || profile.avatar === ''" :class="imgAvatarClass"><span class="initials">{{ mixinGetInitials(profile.name) }}</span></div>
		<img :class="imgAvatarClass" v-else :src="profile.avatar">
	</div>
</template>

<script>
export default {
	name: 'user-avatar',
	methods: {
		
	},
	props: {
		small: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
		};
	},
	computed: {
		profile () {
			return this.$store.getters.getProfile
		},
		imgAvatarClass () {
			if (this.small) {
				return 'avatar avatar-sm';
			}
			return 'avatar avatar-md';
		}
	}
};
</script>