<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
			<user-avatar small/>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ profile.name }}</strong>
    </CDropdownHeader>
    <CDropdownItem @click="onProfile">
      <CIcon name="cil-user" /> Up. račun
    </CDropdownItem>
    <CDropdownItem @click="onSettings">
      <CIcon name="cil-settings" /> Nastavitve
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="onLogout">
      <CIcon name="cil-lock-locked" /> Odjava
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { AUTH_LOGOUT } from "@/store/actions/auth";
import UserAvatar from '@/views/components/UserAvatar';

export default {
  name: 'TheHeaderDropdownAccnt',
	components: {
		'user-avatar': UserAvatar
	},
  data () {
    return { 
      itemsCount: 42
    }
  },
  computed: {
    profile() {
      return this.$store.getters.getProfile
    }
  },
  methods: {
    onProfile: function() {
      this.$router.push('/entities/users/profile');
    },
    onSettings: function() {
      this.$router.push('/entities/users/settings');
    },
    onLogout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push('/login');
      });
    }
  },
  created: function () {
    console.log('Header permissions', this.profile.permissions);
  }
}
</script>

<style scoped>
</style>