<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://avtotehnika-celje.si">ATC e-računi</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Avtotehnika Celje d.o.o.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://avtotehnika-celje.si">ATC BI</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
