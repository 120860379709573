<template>
	<CSidebar
		fixed
		:minimize="minimize"
		:show="show"
		@update:show="(value) => $store.commit('set', ['sidebarShow', value])"
	>
		<CSidebarBrand class="d-md-down-none" to="/">
			<div v-if="!minimize"><img src="logo.png" class="brand-logo-image" alt="Domov"/></div>
			<div v-else><img src="favicon-96x96.png" class="brand-logo-min-image" alt="Domov"/></div>
		</CSidebarBrand>

		<CRenderFunction flat :content-to-render="menuItems" />

		<CSidebarMinimizer
			class="d-md-down-none"
			@click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
		/>
	</CSidebar>
</template>

<script>

export default {
	name: 'TheSidebar',
	data () {
		return {
			menu_items: {
				_name: 'CSidebarNavDropdown',
				name: 'UI',
				route: '/base',
				icon: 'cil-puzzle',
				items: [
					{
						name: 'Colors',
						to: '/theme/colors',
						icon: 'cil-drop'
					}
				]
			}
		}
	},
	computed: {
		menuItems() {
		return this.$store.getters.getMenuItems;
		},
		show () {
			return this.$store.state.coreui.sidebarShow 
		},
		minimize () {
			return this.$store.state.coreui.sidebarMinimize 
		}
	}
}
</script>